<template>
  <v-container
    id="partnerships"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-data-table
      :headers="headers"
      :items="partnerships"
      :search="search"
      :no-data-text="str['partnerships_empty']"
      :no-results-text="str['partnerships_empty']"
      class="elevation-1"
      @click:row="openPartnership"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-dialog
            v-model="dialogPartnership"
            persistent
            max-width="750px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="success"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on"
                @click="openNewPartnership()"
              >
                {{ str['add'] }}
              </v-btn>
            </template>

            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ str['new_partnership'] }}
                </span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="item in data"
                      :key="item.id"
                      :cols="item.col ? item.col : 12"
                      v-show="item.visible"
                    >
                      <div
                        v-if="item.parent || item.col"
                        style="height: 30px"
                      >
                        {{ item.parent ? item.parent : '' }}
                      </div>

                      <v-text-field
                        v-if="item.type === 'input' && !item.inputType"
                        v-model="item.value"
                        :label="item.title"
                        :disabled="item.disabled ? true : false"
                        hide-details
                      />

                      <v-text-field
                        v-if="item.type === 'input' && item.inputType === 'float'"
                        v-model="item.value"
                        :label="item.title"
                        :disabled="item.disabled ? true : false"
                        hide-details
                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                      />

                      <v-text-field
                        v-if="item.type === 'input' && item.inputType === 'integer'"
                        v-model="item.value"
                        :label="item.title"
                        :disabled="item.disabled ? true : false"
                        hide-details
                        oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');"
                      />

                      <v-autocomplete
                        v-if="item.type === 'select'"
                        v-model="item.value"
                        :label="item.title"
                        item-text="label"
                        item-value="value"
                        :items="item.items"
                        :no-data-text="str['no_data']"
                      />

                      <v-checkbox
                        v-if="item.type === 'checkbox'"
                        v-model="item.value"
                        :label="item.title"
                        class="form-field-checkbox"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closePartnership"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  color="success"
                  @click="savePartnership"
                >
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.status="{ item }">
        {{ item.status === 0 ? str['active'] : str['deactivated'] }}
      </template>
      <template v-slot:item.partnership_reg="{ item }">
        {{ item.partnership_reg ? str['yes'] : str['no'] }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="item.status !== statusMap.enabled"
          small
          class="mr-0"
          color="success"
          @click.stop="openEnablePartnershipDialog(item)"
        >
          mdi-account-check
        </v-icon>
        <v-icon
          v-if="item.status !== statusMap.disabled"
          small
          class="mr-0"
          color="error"
          @click.stop="openDisablePartnershipDialog(item)"
        >
          mdi-block-helper
        </v-icon>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'

  export default {
    data () {
      return {
        str: window.strings,
        user: Utils.getUser(),
        partnerships: [],
        dialogPartnership: false,
        data: [],
        search: '',
        headers: [
          { text: window.strings['partnership_name'], value: 'name', align: 'left' },
          { text: window.strings['code'], value: 'code', align: 'center' },
          { text: window.strings['percentage'], value: 'percentage', align: 'center' },
          { text: window.strings['status'], value: 'status', align: 'center' },
          { text: window.strings['receive_only_commission_registrations'], value: 'partnership_reg', align: 'center' },
          { text: window.strings['actions'], value: 'actions', sortable: false, align: 'center' },
        ],
        statusMap: {
          enabled: 0,
          disabled: 1,
        },
      }
    },
    watch: {
      dialogPartnership (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }

        val || this.closePartnership()
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.$isLoading(true)
      this.getPartnershipFields()
      this.getPartnerships()
    },
    methods: {
      getPartnershipFields: function () {
        const packages = this.user && this.user.configurations && this.user.configurations.partnerships ? this.user.configurations.partnerships.packages : null
        const fields = [{
          type: 'input',
          id: 'code',
          title: window.strings['code'],
          value: '',
          visible: true,
        }, {
          type: 'input',
          id: 'name',
          title: window.strings['partnership_name'],
          value: '',
          visible: true,
        }]

        for (let i = 0; i < packages.length; i++) {
          fields.push({
            showParent: true,
            col: 6,
            type: 'input',
            inputType: 'float',
            id: 'package_value_' + i,
            title: window.strings['package_value_€'],
            value: '',
            number: true,
            visible: true,
          }, {
            col: 6,
            type: 'input',
            inputType: 'integer',
            id: 'package_discount_' + i,
            title: window.strings['discount'],
            value: '',
            number: true,
            visible: true,
          }, {
            col: 6,
            type: 'input',
            id: 'package_name_' + i,
            title: '',
            value: '',
            visible: false,
          }, {
            col: 6,
            type: 'input',
            id: 'package_type_' + i,
            title: '',
            value: '',
            number: true,
            visible: false,
          })
        }

        fields.push({
          type: 'input',
          id: 'percentage',
          inputType: 'integer',
          title: window.strings['partnership_percentage'],
          value: '',
          number: true,
          visible: true,
        })

        fields.push({
          type: 'checkbox',
          id: 'partnership_reg',
          title: window.strings['receive_only_commission_registrations'],
          value: '',
          visible: true,
        })

        this.data = fields
      },
      getPartnerships: function () {
        const self = this
        Api.getPartnerships(function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.partnerships = response.data
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openNewPartnership () {
        this.setData()
        this.dialogPartnership = true
      },
      getData: function () {
        const data = {
          partnership: 1,
          type: 0,
          val_mins: 9999999,
          uses_max: 9999999,
          packages: []
        }

        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].id.indexOf('package') > -1) {
            const idSplit = this.data[i].id.split('_')
            const key = idSplit[1]
            const index = idSplit[2]
            
            if (!data.packages[index]) {
              data.packages[index] = {}
            }

            if (this.data[i].number) {
              data.packages[index][key] = parseFloat(this.data[i].value)
            } else {
              data.packages[index][key] = this.data[i].value
            }
          } else {
            if (this.data[i].number) {
              data[this.data[i].id] = parseFloat(this.data[i].value)
            } else {
              if (this.data[i].type === 'checkbox') {
                data[this.data[i].id] = this.data[i].value ? 1 : 0
              } else {
                data[this.data[i].id] = this.data[i].value
              }
            }
          }
        }

        return data
      },
      setData: function () {
        const packages = this.user && this.user.configurations && this.user.configurations.partnerships ? this.user.configurations.partnerships.packages : null

        for (let i = 0; i < this.data.length; i++) {
          if (packages && this.data[i].id.indexOf('package') > -1) {
            const idSplit = this.data[i].id.split('_')
            const key = idSplit[1]
            const index = idSplit[2]

            this.data[i].value = packages[index][key]

            if (this.data[i].showParent) {
              this.data[i].parent = packages[index].name + ' (' + this.getTypeText(packages[index].type) + ')'
            }

          } else {
            this.data[i].value = ''
          }
        }
      },
      getTypeText(type) {
        const promoCodePlanType = Utils.getPromoCodesPlanTypes().find(function (item) {
          return item.value === type
        })
        if (promoCodePlanType) {
          return promoCodePlanType.label
        } else {
          return ''
        }
      },
      closePartnership () {
        this.dialogPartnership = false
      },
      savePartnership () {
        const self = this
        this.$isLoading(true)
        const data = this.getData()

        Api.newPartnership(data, function (response) {
          if (response.success) {
            self.closePartnership()
            self.getPartnerships()
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openPartnership (partnership) {
        Utils.setStorage('partnership', partnership)
        this.$router.navigate.push({ path: '/home/partnership' })
      },
      openEnablePartnershipDialog(partnership) {
        const self = this

        this.$confirm(
          window.strings['want_active_partnership'] + (partnership ? (' "' + partnership.name + '"') : '') + '?',
          '',
          'success',
          Utils.getAlertOptions(true)
        ).then(() => {
          self.updatePartnership(partnership, self.statusMap.enabled)
        }).catch(() => { })
      },
      openDisablePartnershipDialog (partnership) {
        const self = this

        this.$confirm(
          window.strings['want_deactivate_partnership'] + (partnership ? (' "' + partnership.name + '"') : '') + '?',
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.updatePartnership(partnership, self.statusMap.disabled)
        }).catch(() => { })
      },
      updatePartnership (partnership, status) {
        const self = this
        const data = {
          code: partnership.code,
          status: status,
        }

        this.$isLoading(true)
        Api.updatePartnership(data, function (response) {
          if (response.success) {
            self.getPartnerships()
          } else {
            self.$isLoading(false)
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
    },
  }
</script>
